/*global  ajax_object */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/masonry.pkgd.min.js';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.case-results__list').masonry({
    // options...
    itemSelector: '.case-results__item',
    gutter: 50,
  });

  // if ($('.home').length ? $(this).length : 0) {
  //   gsap.registerPlugin(ScrollTrigger);
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: '.home-video',
  //       start: 'top 10%',
  //       // endTrigger: '.home-firm',
  //       end: 'bottom 45%',
  //       scrub: 0.1,
  //       pin: true,
  //       pinSpacing: true,
  //       // duration: { min: 4 },
  //       // delay: 0,
  //       // toggleActions: 'restart none reset none',
  //       // markers: { startColor: 'green', endColor: 'red' },
  //       // onUpdate: (self) => scaleContainer(self.progress), // Custom scaling function
  //     },
  //     // delay: 1,
  //     ease: 'power1.inOut',
  //   });
  //   tl.to('.home-video__video-wrap', {
  //     scaleY: 1,
  //     scaleX: 1,
  //     opacity: 1,
  //   });
  //   // tl.fromTo(
  //   //   '.home-video__video-wrap',
  //   //   { opacity: 1, transformOrigin: '50% 50%' },
  //   //   { opacity: 1 }
  //   // );
  //   tl.to('.home-video__content-wrap', {
  //     opacity: 0.2,
  //   });
  // }
  /**
   * Reviews loadmore
   */
  let reviewPerPage = 5;

  function loadScrollReviews(postType, response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.reviews-posts__item').length;
    let page = $('.review-load').data('page-type');
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        postType: postType,
        action: 'loadmore',
        offset: offset,
        postperpage: reviewPerPage,
        page: page,
      },
      beforeSend: function () {
        $('.ajax-review-loading').hide();
      },
      success: function (response) {
        $('.ajax-review-loading').hide();
        $('.ajax-reviews').append(response.html);
        ScrollOut({
          threshold: 0.97,
          once: true,
          onShown: function (element) {
            if ($(element).is('.ease-order')) {
              $(element)
                .find('.ease-order__item')
                .each(function (i) {
                  let $this = $(this);
                  $(this).attr('data-scroll', '');
                  window.setTimeout(function () {
                    $this.attr('data-scroll', 'in');
                  }, 300 * i);
                });
            }
          },
        });
        // Check if all posts have been loaded
        if ($('.reviews-posts__item').length < response.post_count) {
          $('.ajax-review-loading').show(); // Show loading indicator for more posts
        } else {
          $('.review-load').hide(); // Hide the load more button if no more posts
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.review-load').click(function () {
    let postType = $(this).data('post-type');
    loadScrollReviews(postType);
  });
  /**
   * Show Practice Menu
   */
  $('.current').click(function () {
    let parent = $(this).closest('.practise-servise');
    let item = parent.find('.practice-servise-menu').first();
    item.slideToggle();
    $(this).toggleClass('active');
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.97,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('body').on('mouseenter mouseleave', '[data-item]', function () {
    var dataItemId = $(this).data('item');
    var hoveredText = $('[data-text="' + dataItemId + '"]');

    hoveredText.toggleClass('hovered-text');
  });

  //Attorney section slider
  $('.attorneys-mobile__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
  });

  //Case resul hero section slider
  $('.case-slider-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  });

  //Staff slider
  $('.team-staff__slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // centerMode: true,
        },
      },
    ],
  });

  //Resources  slider
  $('.resources-list__slider').slick({
    infinite: false,
    adaptiveHeight: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  //Testimonial shortcode slider
  let $status = $('.testimonials-shortcode__slider-nav-count');
  let $slickElement = $('.testimonials-shortcode__slider');
  $slickElement.on(
    'init reInit afterChange',
    function (event, slick, currentSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      $status.html(
        '<span class="curent">' + i + '</span>' + '/' + slick.slideCount
      );
    }
  );
  $slickElement.slick({
    infinite: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: $('.testimonials-shortcode__slider-nav-next'),
  });

  //FAQ shortcode slider
  let $faqSliderStatus = $('.page-content__faq-slider-nav-count');
  let $faqSlickElement = $('.page-content__faq-list');
  $faqSlickElement.on(
    'init reInit afterChange',
    function (event, slick, currentSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      $faqSliderStatus.html(
        '<span class="curent">' + i + '</span>' + '/' + slick.slideCount
      );
    }
  );
  $faqSlickElement.slick({
    infinite: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: $('.page-content__faq-slider-nav-next'),
  });

  //blog post load more
  var page = 1;

  $('#load-more').on('click', function () {
    var button = $(this);
    let cat = button.data('cat');
    let offset = $('.post__wrapper').length;

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more',
        category: cat,
        nonce: ajax_object.nonce,
        offset: offset,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        if (response.html) {
          $('.ajax-posts').append(response.html);
          button.text('View More');
        }

        if (!response.has_more) {
          button.hide();
        }
      },
      error: function () {
        button.text('View More');
        console.log('Error loading more posts');
      },
    });
  });

  //video post load more

  $('#load-more-video').on('click', function () {
    var button = $(this);

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more_video',
        page: page,
        nonce: ajax_object.ajax_url.nonce,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        $('.ajax-posts').append(response);
        button.text('View More');
        page++;
      },
      error: function () {
        button.text('View More');
        console.log('err');
      },
    });
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(this).scrollTop() > 0) {
    $('.header-top-bar').addClass('header-top-bar-hide');
  } else {
    $('.header-top-bar').removeClass('header-top-bar-hide');
  }
});
